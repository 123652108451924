<template>
  <div style="max-width: 600px" >

    <portal to="dash-page-header-action">
      <ws-button @click="saveChanges" label="Save" />
    </portal>

    <ws-navigation
        :items="navigationSelect"
        v-model="navigation"
        no-number
    >

      <template #item.public>

        <ws-accordion
            class="mt-5"
            :items="headerParams"
        >

          <template #action.logo="{forcedExpand , item}">
            <div style="min-width: 150px" class="d-flex justify-end">
              <v-chip
                  @click="forcedExpand()"
                  dark
                  :color="wsACCENT"
                  class="px-1 justify-end font-weight-medium pointer mr-5"
              >
                <v-icon small class="ml-2">mdi-{{ !item.expand ? 'pencil' : 'close' }}</v-icon>
                <h5  class="mx-2"> {{ !item.expand  ? $t('Edit') : $t('Cancel') }}</h5>

              </v-chip>
            </div>
          </template>
          <template #action.colors="{forcedExpand , item}">
            <div style="min-width: 150px" class="d-flex justify-end">
              <v-chip
                  @click="forcedExpand()"
                  dark
                  :color="wsACCENT"
                  class="px-1 justify-end font-weight-medium pointer mr-5"
              >
                <v-icon small class="ml-2">mdi-{{ !item.expand ? 'pencil' : 'close' }}</v-icon>
                <h5  class="mx-2"> {{ !item.expand  ? $t('Edit') : $t('Cancel') }}</h5>

              </v-chip>
            </div>
          </template>


          <template #item.logo>
            <div class="pa-5" style="max-width: 500px">

              <v-sheet max-width="450" width="100%" class="mr-4">
                <h5 class="font-weight-regular mb-6" :style="`color : ${wsACCENT}`">
                  {{ $t('settings.customization.public.logo.tooltip') }}
                </h5>
                <image-param-picker
                    v-model="entityData.logo"
                    no-border
                    height="240"
                />

              </v-sheet>

              <a-parameter-switch
                  class="mt-7 "
                  v-model="entityData.public_site_config.HEADER_BUSINESS_TEXT"
                  label="settings.customization.public.logo.display_text"
                  light
              />

            </div>



          </template>

          <template #item.colors>

            <div class="pa-6" style="max-width: 350px">

              <a-parameter-switch
                  class="mb-5 "
                  v-model="entityData.public_site_config.DARK_HEADER"
                  label="settings.customization.public.colors.dark_header"
                  light
              />

              <color-param-picker
                  class="mb-3"
                  v-for="(item) in PUBLIC_COLORS" :key="item.value"
                  v-model="entityData.public_site_config[item.value]"
                  :label="item.text"
                  hide-palette
                  divider
                  font-regular
                  return-color

              />

            </div>

          </template>

          <template #item.font>
            <font-family-select
                v-model="entityData.main_font"
            />
            <ws-button @click="changeFont" label="save"></ws-button>

          </template>


        </ws-accordion>
      </template>

      <template #item.dashboard>

        <div style="max-width: 300px">
          <div style="max-width: 300px">
            <image-param-picker
                v-model="entityData.logo_dashboard"
                no-border
            />
          </div>
        </div>




        <ws-button @click="changeColors" label="changeColors"></ws-button>
        <div   v-for="(_,color) in COLORS" :key="color" class="mb-3">

          <ws-color-picker
              v-model="$store.state.colors.colorsSet[color]"
              :label="color"
          />


        </div>

      </template>

    </ws-navigation>


  </div>
</template>

<script>
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker.vue";
import {mapActions} from "vuex";
import fontFamilySelect from "@/components/AvalonEditor/UI/sidebar/fonts/UI/fontFamilySelect.vue";
import ColorParamPicker from "@/components/AvalonEditor/UI/colorParamPicker.vue";

export default {
  name: "settingsPersonalization",
  components: {ColorParamPicker, fontFamilySelect, imageParamPicker},
  data() {
    return {
      navigation : 'public',
      entityData : {
        public_site_config : {}
      }
    }
  },
  computed : {
    headerParams() {
      return [
        { name : this.$t('Logo') ,
          subtitle : this.$t('settings.customization.public.logo.description'),
          value : 'logo' ,
          icon : 'mdi-image',
          noExpand: true,
          expanded : false
        },
        { name : this.$t('Colors') ,
          subtitle : this.$t('settings.customization.public.colors.description'),
          value : 'colors' ,
          icon : 'mdi-palette',
          expand : false,
          noExpand: true,
          expanded : false
        },
        // { name : this.$t('Font') ,
        //   subtitle : this.$t('settings.customization.public.font.description'),
        //   value : 'font' ,
        //   icon : 'mdi-text',
        //   expand : false,
        //   noExpand: true,
        //   expanded : false
        // },
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('PublicWebsite') , value : 'public'},
        // { text : this.$t('ClientPanel') , value : 'dashboard'}
      ]
    },
    business() {
      return this.$store.state.business.selectedBusiness
    }
  },
  methods : {
    ...mapActions('colors' , [
        'EDIT_PERSONALIZATION_CONFIG'
    ]),

    changeFont() {
      document.documentElement.style.setProperty('--mainFont' , this.entityData.main_font )
      document.documentElement.style.setProperty('--secondaryFont' , this.entityData.main_font )
    },
    changeColors() {
      let data = this.COPY(this.$store.state.colors)
      data.defaultColors = ''
      this.HANDLE_COLORS(data)
    },

    saveChanges() {
      this.notify('Changes Saved' , 'success')
      let result = this.EDIT_PERSONALIZATION_CONFIG(this.entityData)
      if (!result) {
        return this.ERROR()
      }
      this.notify(this.$t('ChangesSaved'))
    }
  },
  beforeMount() {
  },
  mounted() {
    this.entityData = {
      public_site_config : {},
      logo : this.business.logo || null
    }
    const publicConfig = this.business.public_site_config || {}

    this.PUBLIC_COLORS.forEach(color => {
      this.entityData.public_site_config[color.value] = publicConfig[color.value] || this.COLORS[color.value]
    })

    this.PUBLIC_PARAMS.forEach(color => {
      this.entityData.public_site_config[color.value] = publicConfig[color.value] || null
    })


  }
}
</script>



<style scoped>

</style>